"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import { useAuth } from "@context/User";
import { cn } from "@libs/utils";
import { UserStatus } from "@prisma/client";

import { loggedInMenu } from "@configs/features/menus";

const MobileFooterNavbar = () => {
  const pathname = usePathname();
  const { isAuthenticated, status, contentfulPathwayId } = useAuth();

  if (!isAuthenticated || status !== UserStatus.ACTIVE) {
    return null;
  }

  return (
    <div className="flex justify-between md:hidden fixed bottom-0 left-0 w-full bg-white border-t p-2">
      {loggedInMenu.map(({ name, path, icon: Icon }) => {
        if (path === "/pathway" && !contentfulPathwayId) {
          return null;
        }

        return (
          <Link
            key={path}
            href={path}
            className={cn("flex flex-col gap-1 items-center text-sm", {
              "text-primary": pathname === path,
            })}
          >
            <Icon />
            <span className="text-black">{name}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default MobileFooterNavbar;
