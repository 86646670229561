/**
 * new design system colors
 */

// Neutrals

export const GRAY = {
  100: "#161616",
  90: "#262626",
  80: "#393939",
  70: "#525252",
  60: "#6F6F6F",
  50: "#8D8D8D",
  40: "#A8A8A8",
  30: "#C6C6C6",
  20: "#E0E0E0",
  10: "#F4F4F4",
};

// Brand colors

export const ORANGE = {
  100: "#FC6D1D",
  90: "#F37338",
  80: "#F58045",
  70: "#F79C69",
  60: "#F8A97D",
  50: "#FAB792",
  40: "#FBE3D2",
  30: "#FBE3D2",
  20: "#FEF1EC",
  10: "#FEF9F8",
};

export const BROWN = {
  100: "#382B23",
  90: "#4E3B30",
  80: "#6F5546",
  70: "#7D6658",
  60: "#907D70",
  50: "#9E8D82",
  40: "#B9AEA5",
  30: "#D5CDC8",
  20: "#E3DEDC",
  10: "#F2F0EE",
};

export const YELLOW = {
  100: "#FCA702",
  90: "#FBB032",
  80: "#FCB83D",
  70: "#FDBE4D",
  60: "#FECE76",
  50: "#FFD78B",
  40: "#FFE1A3",
  30: "#FEE7BB",
  20: "#FFF1D2",
  10: "#FFF8E9",
};

export const PURPLE = {
  100: "#4F537A",
  90: "#7277B2",
  80: "#747FE7",
  70: "#A1A5D3",
  60: "#CFD2F3",
  50: "#D9DBEF",
  40: "#E3E6F4",
  30: "#EDEFF7",
  20: "#F8F9FF",
  10: "#F7F8FB",
};

// Semantic colors

export const RED /* Error */ = {
  100: "#762821",
  90: "#8B2C21",
  80: "#AA3325",
  70: "#C94131",
  60: "#DC564A",
  50: "#EB776F",
  40: "#EFABA8",
  30: "#F5CBC9",
  20: "#F9E3E2",
  10: "#FCF2F1",
};

export const GREEN /* Success */ = {
  100: "#132717",
  90: "#325839",
  80: "#3D6D44",
  70: "#4B8B54",
  60: "#68B973",
  50: "#7DC489",
  40: "#A7DDB3",
  30: "#CDEDD4",
  20: "#E6F6EA",
  10: "#762821",
};

export const BLUE /* Info */ = {
  100: "#1A2552",
  90: "#253986",
  80: "#273FAA",
  70: "#3761E4",
  60: "#4F80EF",
  50: "#71A3F5",
  40: "#9EC3F9",
  30: "#C5DAFB",
  20: "#DEEAFD",
  10: "#F9FBFF",
};

// Accent colors

export const ACCENT = {
  "neon-violet": "#8736F3",
  "baby-violet": "#DAC6FA",
  "neon-pink": "#CE3675",
  pink: "#DDAAD4",
  "baby-pink": "#FBD7D7",
  magenta: "#EC7B8C",
  beige: "#E0B996",
};

// Default Palette

export const palette = {
  primary: {
    light: "#FD8A4A",
    main: ORANGE[100],
    dark: "#E3621A",
  },
  secondary: {
    light: "#8C776A",
    main: "#6F5545",
    dark: "#594437",
  },
  error: {
    light: "#CA5717",
    main: "#B04C14",
    dark: "#7E370F",
  },
  warning: {
    light: "#CA5717",
    main: "#FFAE12",
    dark: "#F29D11",
  },
  info: {
    light: "#CED2F6",
    main: "#9CA4EE",
    dark: "#747FE7",
  },
  success: {
    light: "#BCE5C2",
    main: "#79CB86",
    dark: "#5DC06F",
  },
  border: {
    main: "#F0F0F0",
  },
};

/* end new design system colors */

export const primary = {
  100: "#FC6D1D",
  90: "#FC7C34",
  80: "#FD8A4A",
  70: "#FD9961",
  60: "#FDA777",
  50: "#FDB68E",
  40: "#FEC5A5",
  30: "#FED3BB",
  20: "#FEE2D2",
  10: "#FFF0E8",
  5: "#FFF8F4",
  150: "#7E370F",
  140: "#974111",
  130: "#B04C14",
  120: "#CA5717",
  110: "#E3621A",
};

export const secondary = {
  100: "#6F5545",
  90: "#7D6658",
  80: "#8C776A",
  70: "#9A887D",
  60: "#A9998F",
  50: "#B7AAA2",
  40: "#C5BBB5",
  30: "#D4CCC7",
  20: "#E2DDDA",
  10: "#F1EEEC",
  5: "#F8F7F6",
  150: "#382B23",
  140: "#433329",
  130: "#4E3B30",
  120: "#594437",
  110: "#644C3E",
  2: "#FBFBFB",
};

export const tertiary = {
  100: "#FFAE12",
  90: "#FFB62A",
  80: "#FFBE41",
  70: "#FFC659",
  60: "#FFCE71",
  50: "#F8D893",
  40: "#FFDFA0",
  30: "#FFE7B8",
  20: "#FFEFD0",
  10: "#FFF7E7",
  5: "#FFFBF3",
  150: "#BE6700",
  140: "#CF7305",
  130: "#D87C0E",
  120: "#E58C10",
  110: "#F29D11",
};

export const white = {
  100: "rgba(255, 255, 255, 1)",
  90: "rgba(255, 255, 255, 0.9)",
  80: "rgba(255, 255, 255, 0.8)",
  70: "rgba(255, 255, 255, 0.7)",
  60: "rgba(255, 255, 255, 0.6)",
  50: "rgba(255, 255, 255, 0.5)",
  40: "rgba(255, 255, 255, 0.4)",
  30: "rgba(255, 255, 255, 0.3)",
  20: "rgba(255, 255, 255, 0.2)",
  10: "rgba(255, 255, 255, 0.1)",
};

export const mint = {
  100: "#79CB86",
  50: "#BCE5C2",
  40: "#C9EACF",
  30: "#D7EFDB",
  20: "#E4F5E7",
  10: "#F2FAF3",
  110: "#5DC06F",
};

export const periwinkle = {
  100: "#9CA4EE",
  50: "#CED2F6",
  40: "#D8DBF8",
  30: "#E1E4FA",
  20: "#EBEDFC",
  10: "#F5F6FD",
  110: "#747FE7",
};
