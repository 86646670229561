"use client";

import { ThemeProvider } from "@emotion/react";
import { SessionProvider } from "next-auth/react";
import dynamic from "next/dynamic";
import { PostHogProvider } from "posthog-js/react";
import { ReactNode, useState } from "react";

import "@/assets/styles/globals.css";
import { AppContextProvider } from "@context/AppContext";
import PopupProvider from "@context/PopupContext";
import UserProvider from "@context/User";
import { initiatePostHog } from "@libs/posthog";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { GoogleAnalytics } from "nextjs-google-analytics";
import posthog from "posthog-js";

import { assembleTheme } from "@styles/theme";

const ToasterNotification = dynamic(() => import("@components/common/ToasterNotification"));

initiatePostHog();

export default function Providers({ children }: { children: ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <SessionProvider refetchOnWindowFocus>
      <ThemeProvider theme={assembleTheme}>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <AppContextProvider>
              <PostHogProvider client={posthog}>
                <PopupProvider>
                  <ToasterNotification />
                  <GoogleAnalytics trackPageViews />
                  <ProgressBar color="#fc6d1d" options={{ showSpinner: false }} shallowRouting />
                  {children}
                </PopupProvider>
              </PostHogProvider>
            </AppContextProvider>
          </UserProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </SessionProvider>
  );
}
