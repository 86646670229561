"use client";

import { ReactNode, createContext, useContext } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";

type AppContextType = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const defaultAppContextValue: AppContextType = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
};

const AppContext = createContext<AppContextType>(defaultAppContextValue);

type AppContextProviderProps = {
  children: ReactNode;
};

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <AppContext.Provider value={{ isMobile, isTablet, isDesktop }}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => useContext<AppContextType>(AppContext);
